import React, { useCallback, useEffect, useState, Suspense } from 'react';
import { Loading } from 'styles/global';
import { useLocation } from 'react-router-dom';
import { useCampaign } from 'hooks/campaign';
import api from 'services/api';
import { ICampaignDataEdit } from 'interfaces/requestsApi';
import { formatDate } from 'utils/formatDate';

import { Container, ContainerStepsProgress, Content, ProgressBar, ProgressBarValue } from './styles';

interface IProps {
    isEdit: boolean;
    id: string;
    isAdmin: boolean;
}

const RegisterCampaignData = React.lazy(() => import('components/RegisterCampaignData'));
const RegisterPrizeImages = React.lazy(() => import('components/RegisterPrizeImages'));
const RegisterCampaignAssociate = React.lazy(() => import('components/RegisterCampaignAssociate'));

export function Campaign(): JSX.Element {
    const { state } = useLocation<IProps>();
    const [currentStep, setCurrentStep] = useState('1');
    const { setIdOfCampaign, campaignIsNotFinished, finishCampaign } = useCampaign();
    const [registerStep, setRegisterStep] = useState<'BASIC_DATA' | 'IMAGES_DATA' | 'ASSOCIATES_DATA'>('BASIC_DATA');
    const [campaignData, setCampaignData] = useState<ICampaignDataEdit>({
        campaignName: '',
        date: '',
        maxAmountOfTickets: 100,
        organizer: '',
        prizeDrawType: 'Loteria Federal',
        prizes: {
            prize1: '',
            prize2: '',
            prize3: '',
            prize4: '',
            prize5: '',
            ticketNumberWinner1: '',
            ticketNumberWinner2: '',
            ticketNumberWinner3: '',
            ticketNumberWinner4: '',
            ticketNumberWinner5: '',
        },
        unitaryValue: '0',
        isActive: false,
        status: 'CREATED',
        regulation: '',
        showTickets: false,
    });

    function handleChangeRegisterStep(step: 'BASIC_DATA' | 'IMAGES_DATA' | 'ASSOCIATES_DATA') {
        setRegisterStep(step);
    }

    function handleGetStepsProgress() {
        if (registerStep === 'BASIC_DATA') return '33%';
        if (registerStep === 'IMAGES_DATA') return '66%';
        return '90%';
    }

    const handleGetCurrentStep = useCallback(() => {
        if (registerStep === 'BASIC_DATA') setCurrentStep('1');
        else if (registerStep === 'IMAGES_DATA') setCurrentStep('2');
        else setCurrentStep('3');
    }, [registerStep]);

    useEffect(() => {
        handleGetCurrentStep();
    }, [registerStep, handleGetCurrentStep]);

    useEffect(() => {
        async function getStatusOfCampaign() {
            try {
                const response = await api.get(`campaigns/${state.id}`);
                if (response.data.data.status === 'FINISHED') finishCampaign();
                else campaignIsNotFinished();
                setCampaignData({
                    campaignName: response.data.data.name,
                    date: formatDate(response.data.data.prizeDrawDate),
                    maxAmountOfTickets: response.data.data.maxAmountOfTickets,
                    organizer: response.data.data.organizerName,
                    prizeDrawType: response.data.data.prizeDrawType,
                    prizes: {
                        prize1: response.data.data.prize1 || '',
                        prize2: response.data.data.prize2 || '',
                        prize3: response.data.data.prize3 || '',
                        prize4: response.data.data.prize4 || '',
                        prize5: response.data.data.prize5 || '',
                        ticketNumberWinner1: response.data.data.ticketNumberWinner1 || '',
                        ticketNumberWinner2: response.data.data.ticketNumberWinner2 || '',
                        ticketNumberWinner3: response.data.data.ticketNumberWinner3 || '',
                        ticketNumberWinner4: response.data.data.ticketNumberWinner4 || '',
                        ticketNumberWinner5: response.data.data.ticketNumberWinner5 || '',
                    },
                    unitaryValue: response.data.data.ticketPrice,
                    isActive: response.data.data.active,
                    status: response.data.data.status,
                    regulation: response.data.data.regulation,
                    showTickets: response.data.data.showTickets,
                });
            } catch (error) {
                campaignIsNotFinished();
            }
        }
        setIdOfCampaign(state.id);
        if (!state.id) campaignIsNotFinished();
        else getStatusOfCampaign();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.id]);

    return (
        <Container>
            <Content>
                <ContainerStepsProgress>
                    <h1>Etapas {state?.isEdit ? 'da edição' : 'do cadastro'}</h1>
                    <span>{currentStep} de 3</span>
                    <ProgressBar>
                        <ProgressBarValue
                            theme={{
                                width: handleGetStepsProgress(),
                            }}
                        />
                    </ProgressBar>
                </ContainerStepsProgress>
                {registerStep === 'BASIC_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterCampaignData
                            isEdit={state?.isEdit || false}
                            id={state?.id}
                            changeRegisterStep={handleChangeRegisterStep}
                            isAdmin={state.isAdmin || false}
                            initialCampaignData={campaignData}
                        />
                    </Suspense>
                )}
                {registerStep === 'IMAGES_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterPrizeImages
                            isAdmin={state.isAdmin || false}
                            changeRegisterStep={handleChangeRegisterStep}
                            isEdit={state?.isEdit}
                        />
                    </Suspense>
                )}
                {registerStep === 'ASSOCIATES_DATA' && (
                    <Suspense fallback={<Loading>Carregando...</Loading>}>
                        <RegisterCampaignAssociate
                            changeRegisterStep={handleChangeRegisterStep}
                            isAdmin={state.isAdmin || false}
                            isEdit={state?.isEdit}
                        />
                    </Suspense>
                )}
            </Content>
        </Container>
    );
}
