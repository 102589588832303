import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { OverlayCampaignFees } from 'components/OverlayCampaignFees';
import { OverlayPublishCampaign } from 'components/OverlayPublishCampaign';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { formatPrizeDrawDate } from 'utils/formatDate';
import api from 'services/api';
import { colors } from 'styles/colors';
import { Status } from 'pages/App/Organizer/HomePage/styles';
import { OverlayLoading } from 'components/OverlayLoading';
import {
    ButtonSetPage,
    Container,
    ContainerFilter,
    ContainerTable,
    ContainerTablePages,
    ContainerTitleFilter,
    Content,
    StatusFilter,
    TableCampaigns,
    Title,
} from './styles';

const PAGINATION_LENGTH = 10;
interface IFee {
    id: string;
    plataformBRL: string;
    partnerBRL: string;
    creditCardVisaPercentage: string;
    creditCardMastercardPercentage: string;
    creditCardEloPercentage: string;
    plataformPercentage: string;
    partnerValue: string;
}
interface ICampaign {
    id: string;
    active: boolean;
    name: string;
    organizerName: string;
    prizeDrawDate: string;
    prizeDrawType: string;
    maxAmountOfTickets: number;
    status: string;
    createdAt: string;
    summary: {
        amountOfTicketsSold: number;
        amountOfTickets: number;
        totalAmountRaised: number;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        drawnTickets: any[];
    };
    fee: IFee;
}

interface IFilter {
    name: string;
    createdAtBegin: string;
    createdAtEnd: string;
    organizer: string;
    status: string;
    active: string;
    group: string;
    affiliateName: string;
}

export function ViewCampaigns(): JSX.Element {
    const [campaigns, setCampaigns] = useState<Array<ICampaign>>([] as Array<ICampaign>);
    const [filterData, setFilterData] = useState<IFilter>({
        name: '',
        createdAtBegin: '',
        createdAtEnd: '',
        organizer: '',
        status: '',
        active: 'true',
        group: '',
        affiliateName: '',
    });
    const history = useHistory();
    const [showOverlayFees, setShowOverlayFees] = useState(false);
    const [pageSelected, setPageSelected] = useState(1);
    const [pages, setPages] = useState<Array<number>>([]);
    const [idToViewFeees, setIdToViewFees] = useState('');
    const { addError, addSuccess } = useToast();
    const [idToPublish, setIdToPublish] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const [showOverlayLoading, setShowOverlayLoading] = useState(false);

    function handleGetPages(total: number) {
        const newPages = [];
        for (let i = 0; i < total / PAGINATION_LENGTH; i += 1) {
            newPages.push(i + 1);
        }
        setPages(newPages);
    }

    const handleShowOverlayFees = () => {
        setShowOverlayFees(!showOverlayFees);
    };

    async function handleGetCampaigns(page: number) {
        try {
            setShowOverlayLoading(true);
            const response = await api.get(`campaigns/?page=${page}`, { params: filterData });
            setCampaigns(response.data.data);
            handleGetPages(response.data.count);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            addError('Erro ao obter campanhas');
        } finally {
            setShowOverlayLoading(false);
        }
    }
    useEffect(() => {
        handleGetCampaigns(pageSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleShowOverlay() {
        setShowOverlay(!showOverlay);
    }

    async function handleTablePagination(page: number) {
        setPageSelected(page);
        handleGetCampaigns(page);
    }

    function getStatusElement(status: string) {
        switch (status) {
            case 'CREATED':
                return (
                    <Status
                        theme={{
                            color: colors.black,
                        }}
                    >
                        Não publicada
                    </Status>
                );
                break;
            case 'REQUESTED':
                return (
                    <Status
                        theme={{
                            color: colors.black,
                        }}
                    >
                        Publicação solicitada
                    </Status>
                );
                break;
            case 'PUBLISHED':
                return (
                    <Status
                        theme={{
                            color: colors.black,
                        }}
                    >
                        Publicada
                    </Status>
                );
                break;
            case 'FINISHED':
                return (
                    <Status
                        theme={{
                            color: colors.black,
                        }}
                    >
                        Finalizada
                    </Status>
                );
                break;
            case 'CANCELLED':
                return (
                    <Status
                        theme={{
                            color: colors.black,
                        }}
                    >
                        Cancelada
                    </Status>
                );
            default:
                <Status theme={{ color: colors.purple }}>-</Status>;
                break;
        }
        return true;
    }

    async function handleActiveCampaign(id: string) {
        try {
            await api.patch(`campaigns/${id}`, { active: true });
            addSuccess('Campanha publicada');
            handleGetCampaigns(pageSelected);
            setShowOverlay(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            addError('Erro ao publicar campanha');
            if (error.response?.data?.error?.message) addError(error.response.data.error.message);
        }
    }
    return (
        <Container>
            <Content>
                {showOverlayLoading && <OverlayLoading />}
                <ContainerTitleFilter>
                    <Title>Campanhas</Title>
                    <ContainerFilter>
                        <div className="big-fields">
                            <div className="container-name">
                                <span>Nome da campanha</span>
                                <Input
                                    name="campaignName"
                                    value={filterData.name}
                                    onChange={e => {
                                        setFilterData({ ...filterData, name: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="created-by">
                                <span>Nome da franquia</span>
                                <Input
                                    name="organizerName"
                                    value={filterData.organizer}
                                    onChange={e => {
                                        setFilterData({ ...filterData, organizer: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="big-fields">
                            <div className="container-name">
                                <span>Nome do fundo</span>
                                <Input
                                    name="groupName"
                                    value={filterData.group}
                                    onChange={e => {
                                        setFilterData({ ...filterData, group: e.target.value });
                                    }}
                                />
                            </div>
                            <div className="created-by">
                                <span>Nome do integrante</span>
                                <Input
                                    name="affiliateName"
                                    value={filterData.affiliateName}
                                    onChange={e => {
                                        setFilterData({ ...filterData, affiliateName: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="container-input">
                            <span>Data de cadastro inicial</span>
                            <Input
                                name="registerDate"
                                type="date"
                                value={filterData.createdAtBegin}
                                onChange={e => {
                                    setFilterData({
                                        ...filterData,
                                        createdAtBegin: e.target.value,
                                    });
                                }}
                            />
                        </div>

                        <div className="container-input">
                            <span>Data de cadastro final</span>
                            <Input
                                name="prizeDrawDate"
                                type="date"
                                value={filterData.createdAtEnd}
                                onChange={e => {
                                    setFilterData({
                                        ...filterData,
                                        createdAtEnd: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="container-select">
                            <span>Status</span>
                            <StatusFilter onChange={e => setFilterData({ ...filterData, status: e.target.value })}>
                                <option value="">Todas</option>
                                <option value="PUBLISHED">Publicada</option>
                                <option value="CREATED">Não publicada</option>
                                <option value="FINISHED">Finalizada</option>
                                <option value="CANCELLED">Cancelada</option>
                                <option value="REQUESTED">Publicação solicitada</option>
                            </StatusFilter>
                        </div>
                        <div className="container-select">
                            <span>Situação</span>
                            <StatusFilter
                                value={filterData.active}
                                onChange={e =>
                                    setFilterData({
                                        ...filterData,
                                        active: e.target.value,
                                    })
                                }
                            >
                                <option value="">Todas</option>
                                <option value="true">Ativa</option>
                                <option value="false">Inativa</option>
                            </StatusFilter>
                        </div>
                        <div className="container-button">
                            <Button
                                color="green"
                                onClick={() => {
                                    handleGetCampaigns(1);
                                    setPageSelected(1);
                                }}
                            >
                                Filtrar
                            </Button>
                        </div>
                    </ContainerFilter>
                </ContainerTitleFilter>
                <ContainerTable>
                    <TableCampaigns>
                        <thead>
                            <tr>
                                <td>Franquia</td>
                                <td>Título da campanha</td>
                                <td>Data da criação</td>
                                <td>Data do sorteio</td>
                                <td>Valor movimentado</td>
                                <td>Progresso</td>
                                <td>Status</td>
                                <td>Ações</td>
                            </tr>
                        </thead>
                        <tbody>
                            {campaigns.map(obj => {
                                return (
                                    <tr key={obj.id}>
                                        <td>{obj.organizerName}</td>
                                        <td>{obj.name}</td>
                                        <td>{formatPrizeDrawDate(obj.createdAt)}</td>
                                        <td>{formatPrizeDrawDate(obj.prizeDrawDate || '')}</td>
                                        <td>R$ {obj.summary?.totalAmountRaised}</td>
                                        <td>
                                            {obj.summary?.amountOfTicketsSold} / {obj.summary.amountOfTickets}
                                        </td>
                                        <td>{getStatusElement(obj.status)}</td>
                                        <td>
                                            <div className="container-buttons">
                                                <Button
                                                    color="purple"
                                                    type="button"
                                                    onClick={() =>
                                                        history.push('editar-campanha', {
                                                            id: obj.id,
                                                            isAdmin: true,
                                                            isEdit: true,
                                                        })
                                                    }
                                                >
                                                    Ver detalhes
                                                </Button>
                                                {obj.status === 'REQUESTED' && (
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            setIdToPublish(obj.id);
                                                            handleShowOverlay();
                                                        }}
                                                    >
                                                        Publicar
                                                    </Button>
                                                )}
                                                <Button
                                                    type="button"
                                                    color="green"
                                                    onClick={() =>
                                                        history.push('compradores', {
                                                            campaignId: obj.id,
                                                            isAdmin: true,
                                                        })
                                                    }
                                                >
                                                    Compradores
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="red"
                                                    onClick={() => {
                                                        setIdToViewFees(obj.fee?.id || '');
                                                        handleShowOverlayFees();
                                                    }}
                                                >
                                                    Taxas
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableCampaigns>
                </ContainerTable>
                <ContainerTablePages>
                    {pages.map(page => {
                        return (
                            <ButtonSetPage
                                key={page}
                                selected={pageSelected === page}
                                onClick={() => handleTablePagination(page)}
                            >
                                {page}
                            </ButtonSetPage>
                        );
                    })}
                </ContainerTablePages>
            </Content>
            {showOverlayFees && (
                <OverlayCampaignFees campaignId={idToViewFeees} handleCloseOverlay={handleShowOverlayFees} />
            )}
            {showOverlay && (
                <OverlayPublishCampaign
                    campaignId={idToPublish}
                    showOverlay={handleShowOverlay}
                    publishCampaign={handleActiveCampaign}
                />
            )}
        </Container>
    );
}
