import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import api from 'services/api';
import { formatPrizeDrawDate } from 'utils/formatDate';
import {
    Container,
    ContainerTable,
    Content,
    Table,
    TicketError,
    TicketIcon,
    ContainerInfos,
    BottomBar,
} from './styles';

interface IAffiliate {
    name: string;
}

interface ICampaign {
    name: string;
    id: string;
    prizeDrawDate: string;
}

interface IMyTickets {
    id: string;
    number: string;
}

interface IUserTicket {
    id: string;
    affiliate: IAffiliate;
    campaign: ICampaign;
    tickets: Array<IMyTickets>;
}

interface IProps {
    cpf: string;
}

export function MyTickets(): JSX.Element {
    const [myTickets, setMyTickets] = useState<Array<IUserTicket>>([] as Array<IUserTicket>);
    const location = useLocation<IProps>();
    const { addError } = useToast();

    useEffect(() => {
        async function handleGetMyTickets() {
            try {
                const response = await api.get(`purchases/document/${location.state.cpf.replace(/\.|-/g, '')}`);
                setMyTickets(response.data.data);
            } catch (error) {
                addError('Erro ao carregar bilhetes');
            }
        }
        handleGetMyTickets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleFormatTicketNumbers(tickets: Array<IMyTickets>) {
        let textTickets = '';
        tickets.forEach(item => {
            textTickets += ` ${item.number} -`;
        });
        if (textTickets[textTickets.length - 1] === '-') textTickets = textTickets.substring(0, textTickets.length - 2);

        return textTickets;
    }

    return (
        <Container>
            <Content>
                <ContainerTable>
                    {myTickets.length ? (
                        <>
                            <ContainerInfos>
                                <TicketIcon />
                                <div className="texts">
                                    <h1>Seus números da sorte</h1>
                                    <ul>
                                        <li>Aqui você encontra seus números da sorte.</li>
                                        <li>
                                            Lembrando que você pode consulta-los também a qualquer momento no email que
                                            recebeu com a confirmação da compra
                                        </li>
                                    </ul>
                                    <BottomBar />
                                </div>
                            </ContainerInfos>
                            <Table>
                                <thead>
                                    <tr>
                                        <td>Campanha</td>
                                        <td>Meus números da sorte</td>
                                        <td>Integrante</td>
                                        <td>Data do sorteio</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myTickets.map(obj => {
                                        return (
                                            <tr key={obj.id}>
                                                <td>{obj.campaign?.name}</td>

                                                <td>{handleFormatTicketNumbers(obj.tickets)}</td>
                                                <td>{obj.affiliate?.name}</td>
                                                {obj.campaign?.prizeDrawDate && (
                                                    <td>{formatPrizeDrawDate(obj.campaign?.prizeDrawDate)}</td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <TicketError>Você ainda não possui bilhetes 😥</TicketError>
                    )}
                </ContainerTable>
            </Content>
        </Container>
    );
}
