/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { useLocation, useHistory } from 'react-router-dom';
import { useToast } from 'hooks/toast';
import { Carousel } from 'react-bootstrap';
import defaultImage from 'assets/images/img/defaultPrizeDrawImage.png';

import api from 'services/api';
import { formatDateAndHoursUTC, formatPrizeDrawDate } from 'utils/formatDate';
import { ValidateImageFormat } from 'utils/validateImageFormat';
import { OverlayCancelPurchase } from 'components/OverlayCancelPurchase';
import { Input } from 'components/Input';
import { OverlayLoading } from 'components/OverlayLoading';

import {
    CampaignCard,
    CampaingnResume,
    Container,
    ContainerButtons,
    ContainerCampaign,
    ContainerFilter,
    ContainerTable,
    ContainerTablePages,
    Content,
    FilterContainer,
    FilterRadio,
    ImageCard,
    PrizeDrawImage,
    PrizeDrawName,
    ProfitMarginContainer,
    ProfitMarginItem,
    ProgressBar,
    ProgressBarValue,
    PurchaseStatus,
    TableSales,
} from './styles';

const PAGINATION_LENGTH = 100;

interface IPrizeDrawPhoto {
    id: string;
    name: string;
    url: string;
}

interface ICampaignData {
    active: boolean;
    id: string;
    name: string;
    organizerName: string;
    maxAmountOfTickets: number;
    prize1: string | null;
    prize2: string | null;
    prize3: string | null;
    prize4: string | null;
    prize5: string | null;
    prizeDrawDate: string;
    prizeDrawType: string;
    prizePhotos: Array<IPrizeDrawPhoto>;
    regulation: string | null;
    status: string;
    ticketNumberWinner1: string | null;
    ticketNumberWinner2: string | null;
    ticketNumberWinner3: string | null;
    ticketNumberWinner4: string | null;
    ticketNumberWinner5: string | null;
    ticketPrice: string;
}

interface ITicket {
    id: string;
    number: string;
    price: string;
}

interface IAffiliate {
    name: string;
    email: string;
    group: string;
}

interface IPurchase {
    id: string;
    clientName: string;
    clientEmail: string;
    phone: string;
    createdAt: string;
    tickets: Array<ITicket>;
    affiliate: IAffiliate;
    totalValue: number;
    creditCardBrand: string;
    creditCardFeeValue?: number;
    partnerFeeValue?: number;
    totalValueWithFees?: number;
    plataformFeeValue?: number;
    paymentType?: string;
    status: string;
}

interface ICampaignSummary {
    amountOfTickets: number;
    amountOfTicketsSold: number;
    totalAmountRaised: number;
    profitMargins: {
        organizer?: number;
        platform?: number;
        partner?: number;
        creditCard?: number;
    };
}

interface IProps {
    campaignId: string;
    isAdmin: boolean;
}

interface IFilter {
    group: string;
    affiliate: string;
    status: string;
}

export function Buyers(): JSX.Element {
    const [pageSelected, setPageSelected] = useState(1);
    const [campaign, setCampaign] = useState<ICampaignData>({} as ICampaignData);
    const [campaignSummary, setCampaignSummary] = useState<ICampaignSummary>({} as ICampaignSummary);
    const [purchases, setPurchases] = useState<Array<IPurchase>>([] as Array<IPurchase>);
    const [showOverlay, setShowOverlay] = useState(false);
    const [idToCancel, setIdToCancel] = useState('');
    const [pages, setPages] = useState<Array<number>>([]);
    const [isLoading, setIsloading] = useState(false);
    const [isloadingCalculoFechamento, setIsloadingCalculoFechamento] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(1);
    const location = useLocation<IProps>();
    const { addSuccess, addError, addWarning } = useToast();
    const history = useHistory();
    const [showOverlayLoading, setShowOverlayLoading] = useState(false);
    const [filterData, setFilterData] = useState<IFilter>({
        group: '',
        affiliate: '',
        status: 'succeeded',
    });

    function handleGetPages(total: number) {
        const newPages = [];
        for (let i = 0; i < total / PAGINATION_LENGTH; i += 1) {
            newPages.push(i + 1);
        }
        setPages(newPages);
    }

    async function handleGetPurchasesOfCampaign(pagina: number) {
        try {
            setShowOverlayLoading(true);
            const response = await api.get(`purchases/campaign/${location.state.campaignId}?page=${pagina}`, {
                params: filterData,
            });
            if (response) {
                setCampaign(response.data.campaign);
                setPurchases(response.data.purchases.data);
                setCampaignSummary(response.data.campaingnSummary);
            }
            handleGetPages(response.data.purchases.count);
        } catch (error) {
            addError('Erro ao carregar vendas');
        } finally {
            setShowOverlayLoading(false);
        }
    }

    async function handleTablePagination(page: number) {
        setPageSelected(page);
        handleGetPurchasesOfCampaign(page);
    }

    useEffect(() => {
        setSelectedFilter(1);
        handleGetPurchasesOfCampaign(pageSelected);
    }, []);

    function handleFormatTicketNumbers(tickets: Array<ITicket>) {
        let textTickets = '';
        tickets.forEach(item => {
            textTickets += ` ${item.number} -`;
        });
        if (textTickets[textTickets.length - 1] === '-') textTickets = textTickets.substring(0, textTickets.length - 2);

        return textTickets;
    }

    async function handleExportSheet() {
        try {
            setShowOverlayLoading(true);
            setIsloading(true);
            const response = await api.get(`purchases/report/campaign/${campaign.id}`, {
                responseType: 'arraybuffer',
                params: filterData,
            });
            const buffer = Buffer.from(response.data, 'utf-8');
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            const date = `${new Date().toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
            })}`;
            link.setAttribute('download', `${campaign.name}-${date.substring(0, 10)}.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            addError('Não foi possível exportar as compras dessa campanha');
        } finally {
            setIsloading(false);
            setShowOverlayLoading(false);
        }
    }
    function handleShowOverlay() {
        setShowOverlay(!showOverlay);
    }

    async function handleCancelPurchase(purchaseId: string) {
        try {
            await api.patch(`purchases/cancel/${purchaseId}`);
            addSuccess('Compra cancelada');
            handleGetPurchasesOfCampaign(pageSelected);
            setShowOverlay(false);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            if (error.response.data.error.message)
                addError(`Erro ao cancelar compra: ${error.response.data.error.message}`);
            else addError('Erro ao cancelar compra');
        }
    }

    function handleGetTicketSoldPercentage(total: number | undefined, sold: number | undefined) {
        if (total && sold) return `${Math.round((sold / total) * 100)}%`;
        return '0%';
    }

    const getPaymentType = (paymentType?: string) => {
        switch (paymentType) {
            case 'credit':
                return 'Cartão de crédito';
            case 'pix':
                return 'PIX';
            default:
                return null;
        }
    };

    const getStatus = (status: string) => {
        switch (status) {
            case 'succeeded':
                return 'Concluída';
            case 'pending':
                return 'Pendente';
            case 'canceled':
            case 'failed':
                return 'Cancelada';
            case 'canceled_manually':
                return 'Cancelada manualmente';
            default:
                return 'Concluída';
        }
    };

    async function handleClosingCalculationAccounting() {
        try {
            setShowOverlayLoading(true);
            setIsloadingCalculoFechamento(true);
            addWarning('Realizando cálculo de fechamento. Aguarde...');
            const response = await api.get(`purchases/closing-calculation/campaign/${campaign.id}`, {
                responseType: 'arraybuffer',
            });
            const buffer = Buffer.from(response.data, 'utf-8');
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement('a');
            link.href = url;
            const date = `${new Date().toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
            })}`;
            link.setAttribute(
                'download',
                `calculo_fechamento_${campaign.name}-${date.substring(0, 10)}-${date.substring(12, 20)}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            addSuccess('Cálculo de fechamento realizado com sucesso');
        } catch (error) {
            addError('Erro ao realizar cálculo de fechamento');
        } finally {
            setIsloadingCalculoFechamento(false);
            setShowOverlayLoading(false);
        }
    }

    return (
        <Container>
            <Content>
                {showOverlayLoading && <OverlayLoading />}
                <ContainerCampaign>
                    <CampaignCard>
                        <ImageCard>
                            <Carousel>
                                {campaign.prizePhotos?.map(image => {
                                    return (
                                        <Carousel.Item key={image.id}>
                                            <PrizeDrawImage
                                                className="d-block w-100"
                                                src={ValidateImageFormat(image.url) ? image.url : defaultImage}
                                                alt={image.name}
                                            />
                                            <Carousel.Caption>
                                                <PrizeDrawName className="prize-draw-name">
                                                    {ValidateImageFormat(image.url) ? image.name : 'Prêmio'}
                                                </PrizeDrawName>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    );
                                })}

                                {!campaign.prizePhotos?.length && (
                                    <Carousel.Item>
                                        <PrizeDrawImage
                                            className="d-block w-100"
                                            src={defaultImage}
                                            alt="Não há fotos dos prêmios dessa campanha"
                                        />
                                    </Carousel.Item>
                                )}
                            </Carousel>
                        </ImageCard>
                        <div className="campaign-informations">
                            <h1>{campaign.name}</h1>
                            <span>
                                Valor unitário:{' '}
                                {Number(campaign.ticketPrice).toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    style: 'currency',
                                    currency: 'BRL',
                                })}
                            </span>
                            <span>
                                Data do sorteio: {campaign.prizeDrawDate && formatPrizeDrawDate(campaign.prizeDrawDate)}
                            </span>
                            <div className="prizes">
                                <strong>Prêmios:</strong>
                                {campaign.prize1 && <p>{campaign.prize1}</p>}
                                {campaign.prize2 && <p>{campaign.prize2}</p>}
                                {campaign.prize3 && <p>{campaign.prize3}</p>}
                                {campaign.prize4 && <p>{campaign.prize4}</p>}
                                {campaign.prize5 && <p>{campaign.prize5}</p>}
                            </div>
                        </div>
                    </CampaignCard>
                    <CampaingnResume>
                        <h1>Resumo</h1>
                        <span>Valor arrecadado: R$ {campaignSummary?.totalAmountRaised}</span>
                        <span className="sold-number">Números vendidos: {campaignSummary?.amountOfTicketsSold}</span>
                        <ProgressBar>
                            <ProgressBarValue
                                theme={{
                                    value: handleGetTicketSoldPercentage(
                                        campaignSummary?.amountOfTickets,
                                        campaignSummary?.amountOfTicketsSold
                                    ),
                                }}
                            >
                                <span>
                                    {handleGetTicketSoldPercentage(
                                        campaignSummary?.amountOfTickets,
                                        campaignSummary?.amountOfTicketsSold
                                    )}
                                </span>
                            </ProgressBarValue>
                        </ProgressBar>
                        <div>
                            <span>Margens de lucro:</span>
                            <ProfitMarginContainer>
                                {!!campaignSummary.profitMargins?.organizer && (
                                    <ProfitMarginItem>
                                        <h3>Organizador</h3>
                                        <span>
                                            {campaignSummary.profitMargins?.organizer?.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </span>
                                    </ProfitMarginItem>
                                )}
                                {!!campaignSummary.profitMargins?.creditCard && (
                                    <ProfitMarginItem>
                                        <h3>Cartão de crédito</h3>
                                        <span>
                                            {campaignSummary.profitMargins?.creditCard?.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </span>
                                    </ProfitMarginItem>
                                )}
                                {!!campaignSummary.profitMargins?.partner && (
                                    <ProfitMarginItem>
                                        <h3>Parceiro</h3>
                                        <span>
                                            {campaignSummary.profitMargins?.partner?.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </span>
                                    </ProfitMarginItem>
                                )}
                                {!!campaignSummary.profitMargins?.platform && (
                                    <ProfitMarginItem>
                                        <h3>Taxa do iRifei</h3>
                                        <span>
                                            {campaignSummary.profitMargins?.platform?.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </span>
                                    </ProfitMarginItem>
                                )}
                            </ProfitMarginContainer>
                        </div>
                        <p>
                            O resumo considera apenas as compras com status <strong>Concluído</strong>
                        </p>
                    </CampaingnResume>
                </ContainerCampaign>

                <ContainerFilter>
                    <div className="big-fields">
                        <div className="container-name">
                            <span>Nome do fundo</span>
                            <Input
                                name="groupName"
                                value={filterData.group}
                                onChange={e => {
                                    setFilterData({ ...filterData, group: e.target.value });
                                }}
                            />
                        </div>
                        <div className="container-name">
                            <span>Nome do integrante</span>
                            <Input
                                name="affiliateName"
                                value={filterData.affiliate}
                                onChange={e => {
                                    setFilterData({ ...filterData, affiliate: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                    <FilterContainer>
                        <span>Status: </span>
                        <FilterRadio>
                            <input
                                type="radio"
                                id="filterAll"
                                value="all"
                                checked={selectedFilter === 0}
                                onChange={() => {
                                    setSelectedFilter(0);
                                    setFilterData({ ...filterData, status: '' });
                                }}
                            />
                            <label htmlFor="filterAll">Todos</label>
                        </FilterRadio>
                        <FilterRadio>
                            <input
                                type="radio"
                                id="filterSucceeded"
                                value="succeeded"
                                checked={selectedFilter === 1}
                                onChange={() => {
                                    setSelectedFilter(1);
                                    setFilterData({ ...filterData, status: 'succeeded' });
                                }}
                            />
                            <label htmlFor="filterSucceeded">Concluído</label>
                        </FilterRadio>
                        <FilterRadio>
                            <input
                                type="radio"
                                id="filterCanceled"
                                value="canceled"
                                checked={selectedFilter === 2}
                                onChange={() => {
                                    setSelectedFilter(2);
                                    setFilterData({ ...filterData, status: 'canceled' });
                                }}
                            />
                            <label htmlFor="filterCanceled">Cancelada</label>
                        </FilterRadio>
                    </FilterContainer>
                </ContainerFilter>
                <ContainerButtons>
                    <Button
                        color="green"
                        onClick={() => {
                            handleGetPurchasesOfCampaign(1);
                            setPageSelected(1);
                        }}
                    >
                        Filtrar
                    </Button>
                    <Button color="red" onClick={() => history.goBack()}>
                        Voltar
                    </Button>
                    <Button color="green" onClick={() => handleExportSheet()}>
                        {isLoading ? 'Exportando...' : 'Exportar'}
                    </Button>
                    <Button
                        color="green"
                        onClick={() => handleClosingCalculationAccounting()}
                        disabled={isloadingCalculoFechamento}
                    >
                        {isloadingCalculoFechamento ? 'Calculando...' : 'Cálculo de fechamento'}
                    </Button>
                </ContainerButtons>
                <ContainerTable>
                    <TableSales>
                        <thead>
                            <tr>
                                <td>Qtde. bilhetes</td>
                                <td>Nºs dos bilhetes</td>
                                <td>Fundo</td>
                                <td>Integrante</td>
                                <td>Data da compra</td>
                                <td>Nome do comprador</td>
                                <td>Email do comprador</td>
                                <td>Tel do comprador</td>
                                {location.state.isAdmin && (
                                    <>
                                        <td>Tipo de pagamento</td>
                                        <td>Taxa do iRifei</td>
                                        <td>Taxa do parceiro</td>
                                        <td>Taxa do cartão</td>
                                    </>
                                )}
                                <td>Total da compra (líquida)</td>
                                {location.state.isAdmin && (
                                    <>
                                        <td>Total da compra com taxas</td>
                                    </>
                                )}
                                <td>Status</td>
                                {location.state.isAdmin && (
                                    <>
                                        <td>Controle de compras</td>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {purchases.map(obj => {
                                return (
                                    <tr key={obj.id}>
                                        <td>{obj?.tickets?.length}</td>
                                        <td>{handleFormatTicketNumbers(obj.tickets)}</td>
                                        <td>{obj?.affiliate?.group}</td>
                                        <td>{obj?.affiliate?.name}</td>
                                        <td>{formatDateAndHoursUTC(obj?.createdAt)}</td>
                                        <td>{obj?.clientName}</td>
                                        <td>{obj?.clientEmail}</td>
                                        <td>{obj?.phone}</td>
                                        {obj?.paymentType && <td>{getPaymentType(obj.paymentType)}</td>}
                                        {obj?.plataformFeeValue && (
                                            <td>
                                                R${' '}
                                                {obj?.plataformFeeValue?.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}{' '}
                                            </td>
                                        )}
                                        {obj?.partnerFeeValue && (
                                            <td>
                                                R${' '}
                                                {obj?.partnerFeeValue?.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}{' '}
                                            </td>
                                        )}
                                        {obj?.creditCardFeeValue && (
                                            <td>
                                                R${' '}
                                                {obj?.creditCardFeeValue?.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}{' '}
                                            </td>
                                        )}

                                        <td>
                                            R${' '}
                                            {obj?.totalValue?.toLocaleString('pt-BR', {
                                                minimumFractionDigits: 2,
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}
                                        </td>
                                        {obj?.totalValueWithFees && (
                                            <td>
                                                R${' '}
                                                {obj?.totalValueWithFees?.toLocaleString('pt-BR', {
                                                    minimumFractionDigits: 2,
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                })}{' '}
                                            </td>
                                        )}
                                        <td>
                                            <PurchaseStatus $status={getStatus(obj.status)}>
                                                {getStatus(obj.status)}
                                            </PurchaseStatus>
                                        </td>
                                        {location.state.isAdmin && (
                                            <>
                                                <td className="centered-td">
                                                    {obj.status === 'succeeded' ? (
                                                        <Button
                                                            color="red"
                                                            onClick={() => {
                                                                setIdToCancel(obj.id);
                                                                handleShowOverlay();
                                                            }}
                                                        >
                                                            Cancelar
                                                        </Button>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableSales>
                    {showOverlay && (
                        <OverlayCancelPurchase
                            purchaseId={idToCancel}
                            showOverlay={handleShowOverlay}
                            cancelPurchase={handleCancelPurchase}
                        />
                    )}
                </ContainerTable>
                <ContainerTablePages>
                    Página:
                    <select
                        value={pageSelected}
                        onChange={event => handleTablePagination(parseInt(event.target.value, 10))}
                    >
                        {pages.map(obj => (
                            <option key={obj} value={obj}>
                                {obj}
                            </option>
                        ))}
                    </select>
                </ContainerTablePages>
            </Content>
        </Container>
    );
}
