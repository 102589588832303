/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import fingerCrossed from 'assets/images/svg/fingers-crossed.svg';
import creditCard from 'assets/images/svg/credit-card.svg';
import selection from 'assets/images/svg/selection.svg';
import pixImage from 'assets/images/img/pix_powered_by_banco_central.png';
import { IoTrophy } from 'react-icons/io5';
import Cards from 'react-credit-cards';
import { PixPaymentMethod } from 'components/PixPaymentMethod';

import { RiQuestionFill } from 'react-icons/ri';

import { buyTicketsWithCreditCardValidator, buyTicketsWithPixValidator } from 'validators/buyTicket';
import { useToast } from 'hooks/toast';
import { useCampaign } from 'hooks/campaign';
import getValidationErrors from 'utils/getValidationErrors';
import * as Yup from 'yup';
import api from 'services/api';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { Winners } from 'components/Winners';
import { Carousel } from 'react-bootstrap';
import defaultImage from 'assets/images/img/defaultPrizeDrawImage.png';
import { IBuyTicketData } from 'interfaces/requestsApi';
import { formatPrizeDrawDate } from 'utils/formatDate';
import { OverlayTermsOfService } from 'components/OverlayTermsOfService';
import { OverlayTermsOfCampaign } from 'components/OverlayTermsOfCampaign';
import { OverlayViewImage } from 'components/OverlayViewImage';
import { FaCreditCard } from 'react-icons/fa';
import { AxiosError } from 'axios';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import 'react-credit-cards/es/styles-compiled.css';
import { colors } from 'styles/colors';
import {
    BottomBar,
    BuyerInformation,
    ChoosePaymentMethods,
    Container,
    ContainerButton,
    ContainerCheckbox,
    ContainerCvv,
    ContainerHowItWorks,
    ContainerInformations,
    ContainerInformationsClosedCampaign,
    ContainerNumberOfTickets,
    ContainerPayment,
    ContainerPaymentMethod,
    ContainerPurchaseSummary,
    ContainerRegulament,
    ContainerSteps,
    ContainerTicket,
    ContainerTicketInformations,
    ContainerTitleTicket,
    ContainerValidity,
    ContainerValityCvv,
    Content,
    ContentTicket,
    Error,
    ImageTicket,
    InputPolicyTerms,
    NumberOfPrizeDraw,
    PrizeDrawImage,
    PrizeDrawName,
    Prizes,
    ProgressBar,
    ProgressBarValue,
    PurchaseTitle,
    Step,
    Subtitle,
    Ticket,
    TicketTitle,
    TotalValue,
} from './styles';

const TAXA_VALUE = 0.15;
const RENDER_CAMPAIGN = 1;
const RENDER_WINNERS = 2;
const ERROR_AWAIT_PUBLISH_CAMPAIGN = 3;
const ERROR_AWAIT_FINISH_CAMPAIGN = 4;
const ERROR_CAMPAIGN_NOT_FOUND = 5;
const RECAPTCHA_SITE_KEY = '6Ld0LuIqAAAAAA4bIIV6pwjRi8W158WKVuyWQB7I';

type PixPaymentResponseData = {
    paymentType: string;
    status: 'string';
    paymentMethod: {
        paymentCompanyProvider: string;
        pixQrcode: string;
        pixExpirationTimeMilliseconds: number;
    };
};

function BuyTicketContent() {
    const [showNotesAboutTickets, setShowNotesAboutTickets] = useState(false);
    const [numberOfTickets, setNumberOfTickets] = useState(1);
    const [cardNumber, setCardNumber] = useState('');
    const [cardValidityYears, setCardValidityYears] = useState<Array<number>>([]);
    const [cardCpf, setCardCpf] = useState('');
    const [cardName, setCardName] = useState('');
    const [validityMonth, setValidityMonth] = useState(new Date().getMonth() + 1);
    const [validityYear, setValidityYear] = useState(new Date().getFullYear());
    const [cvv, setCvv] = useState('');
    const [buyerName, setBuyerName] = useState('');
    const [buyerCpf, setBuyerCpf] = useState('');
    const [buyerEmail, setBuyerEmail] = useState('');
    const [buyerTelephone, setBuyerTelephone] = useState('');
    const { addError, addSuccess } = useToast();
    const [errors, setErrors] = useState({});
    const [campaign, setCampaign] = useState<IBuyTicketData>();
    const { pathname } = useLocation();
    const { handleSetCampaignPage } = useCampaign();
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorPolicyTerms, setIsErrorPolicyTerms] = useState(false);
    const [isErrorCampaignTerms, setIsErrorCampaignTerms] = useState(false);
    const [policyTerms, setPolicyTerms] = useState(false);
    const [campaignTerms, setCampaignTerms] = useState(false);
    const [showTermsOfService, setShowTermsOfService] = useState(false);
    const [showTermsOfCampaign, setShowTermsOfCampaign] = useState(false);
    const [showOverlayImage, setShowOverlayImage] = useState(false);
    const [imageToPreview, setImageToPreview] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [inputFocus, setInputFocus] = useState<any>('cardNumber');
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState('credit');
    const [purchaseTicketResponseData, setPurchaseTicketResponseData] = useState<PixPaymentResponseData | null>(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(e.target.value);
    };

    function handleInputFocus(element: string) {
        setInputFocus(element);
    }

    function handleShowOverlayImage() {
        setShowOverlayImage(!showOverlayImage);
    }

    const handleShowTermsOfService = () => {
        setShowTermsOfService(!showTermsOfService);
    };

    const handleShowTermsOfCampaign = () => {
        setShowTermsOfCampaign(!showTermsOfCampaign);
    };

    const handleGetAffiliateHash = useCallback(() => {
        const hashOfPath = pathname.split('/');
        return hashOfPath[hashOfPath.length - 1];
    }, [pathname]);

    useEffect(() => {
        handleSetCampaignPage(pathname);
    }, [pathname]);

    async function getToken() {
        if (!executeRecaptcha) {
            return;
        }
        const token = await executeRecaptcha('buy_ticket');

        // eslint-disable-next-line consistent-return
        return token;
    }

    function handleCardValidityYear() {
        setCardValidityYears([]);
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        for (let i = 0; i <= 20; i += 1) {
            cardValidityYears.push(currentYear + i);
        }
        setCardValidityYears([...cardValidityYears]);
    }

    useEffect(() => {
        handleGetAffiliateHash();
        async function handleGetCampaign() {
            try {
                const response = await api.get(`campaigns/affiliate/page/${handleGetAffiliateHash()}`);
                if (response) setCampaign(response.data.data);
            } catch (error) {
                addError('Não foi possível obter os dados da campanha');
            } finally {
                setIsLoadingScreen(false);
            }
        }
        handleGetCampaign();
        handleCardValidityYear();

        return () => {
            setCampaign({
                affiliateAmountOfTickets: 0,
                affiliateName: '',
                amountTicketsSold: 0,
                campaignName: '',
                organizerName: '',
                prize1: '',
                prize2: '',
                prize3: '',
                prize4: '',
                prize5: '',
                prizeDrawDate: '',
                prizePhotos: [],
                ticketPrice: 0,
                campaignRegulation: null,
                campaignStatus: 'PUBLISHED',
                feeValuePerTicket: 0,
            });
        };
    }, []);

    function handleGetTicketSoldPercentage(total: number | undefined, sold: number | undefined) {
        if (total && sold) return `${((sold / total) * 100).toFixed(0)}%`;
        return '0%';
    }

    const handleBuyTicket = useCallback(async () => {
        try {
            setErrors({});
            setIsErrorPolicyTerms(false);
            setIsErrorCampaignTerms(false);

            if (paymentMethod === 'pix') {
                await buyTicketsWithPixValidator.validate(
                    {
                        buyerName,
                        buyerEmail,
                        buyerTelephone,
                        buyerCpf,
                    },
                    { abortEarly: false }
                );

                try {
                    setIsLoading(true);
                    const data = {
                        urlHash: handleGetAffiliateHash(),
                        paymentType: 'pix',
                        quantity: numberOfTickets,
                        customer: {
                            name: buyerName.trim(),
                            document: buyerCpf.replace(/\.|-/g, ''),
                            email: buyerEmail.trim(),
                            phone: buyerTelephone.replace(/[^\d]+/g, ''),
                        },
                        agreeWithRegulation: policyTerms,
                    };

                    const policyTermsChecked = document.getElementById('policyTerms') as HTMLInputElement;
                    const campaignTermsChecked = document.getElementById('campaignTerms') as HTMLInputElement;

                    if (!policyTermsChecked.checked || !campaignTermsChecked.checked) {
                        setIsLoading(false);
                        addError('É necessário o aceite dos termos de serviço e do regulamento da campanha.');
                        return;
                    }

                    const response = await api.post('purchases/ticket', data);

                    const pixData = response.data as PixPaymentResponseData;

                    setPurchaseTicketResponseData(pixData);

                    addSuccess('Compra reservada com sucesso.');
                    addSuccess('Efetue o pagamento utilizando o QR Code ou copie e cole o texto informado abaixo.');
                } catch (err) {
                    const error = err as AxiosError;
                    addError('Erro ao efetuar compra via PIX');
                    if (error.response?.data?.error?.message === 'ValidationError') {
                        addError('Dados inválidos, confira os dados preenchidos e tente novamente.');
                        return;
                    }
                    if (error.response?.data?.error?.message) addError(error.response.data.error.message);
                    return;
                } finally {
                    setIsLoading(false);
                }

                return;
            }

            if (paymentMethod === 'credit') {
                await buyTicketsWithCreditCardValidator.validate(
                    {
                        buyerName,
                        buyerEmail,
                        buyerTelephone,
                        buyerCpf,
                        cardName,
                        cardNumber,
                        cardCpf,
                        cvv,
                    },
                    { abortEarly: false }
                );

                try {
                    setIsLoading(true);

                    const token = await getToken();
                    const data = {
                        urlHash: handleGetAffiliateHash(),
                        paymentType: 'credit',
                        paymentMethod: 'card',
                        quantity: numberOfTickets,
                        card: {
                            cardNumber: cardNumber.replace(/\s/g, ''),
                            securityCode: cvv.replace(/_/g, ''),
                            holderName: cardName,
                            expirationMonth: validityMonth,
                            expirationYear: validityYear,
                        },
                        customer: {
                            name: buyerName.trim(),
                            document: buyerCpf.replace(/\.|-/g, ''),
                            email: buyerEmail.trim(),
                            phone: buyerTelephone.replace(/[^\d]+/g, ''),
                        },
                        agreeWithRegulation: policyTerms,
                        recaptchaToken: token,
                    };
                    const policyTermsChecked = document.getElementById('policyTerms') as HTMLInputElement;
                    const campaignTermsChecked = document.getElementById('campaignTerms') as HTMLInputElement;

                    if (!policyTermsChecked.checked || !campaignTermsChecked.checked) {
                        setIsLoading(false);
                        addError('É necessário o aceite dos termos de serviço e do regulamento da campanha.');
                        return;
                    }

                    await api.post('purchases/ticket', data);
                    addSuccess('Compra efetuada com sucesso');
                    addSuccess('Os números da sorte foram enviados para o seu email');
                    // eslint-disable-next-line func-names
                    setInterval(function () {
                        document.location.reload();
                    }, 5000); // 5segundos
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (err: any) {
                    addError('Erro ao efetuar compra');
                    if (err.response.data.error.message) addError(err.response.data.error.message);
                    return;
                    // eslint-disable-next-line no-empty
                } finally {
                    setIsLoading(false);
                }

                return;
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                setErrors(getValidationErrors(err));
                addError('Todos os campos devem ser preenchidos');
                return;
            }

            addError('Ocorreu um erro inesperado. Tente novamente.');
        }
    }, [
        buyerName,
        buyerEmail,
        buyerTelephone,
        buyerCpf,
        cardName,
        cardNumber,
        cardCpf,
        cvv,
        addError,
        addSuccess,
        handleGetAffiliateHash,
        numberOfTickets,
        validityMonth,
        validityYear,
        policyTerms,
        paymentMethod,
    ]);

    function handleShowNotesAboutTicket() {
        setShowNotesAboutTickets(!showNotesAboutTickets);
    }

    const handleCalculateTotalPurchase = (quantity: number, ticketValue: number) => {
        return quantity * ticketValue;
    };
    const handleCalculateTaxa = () => {
        if (!campaign?.ticketPrice || !campaign?.feeValuePerTicket) return 'R$ 0';
        return ((campaign?.feeValuePerTicket || TAXA_VALUE) * numberOfTickets).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL',
        });
    };

    const purchaseValue = useMemo(() => handleCalculateTotalPurchase(numberOfTickets, campaign?.ticketPrice || 0), [
        numberOfTickets,
        campaign?.ticketPrice,
    ]);
    const valueWithTaxa = useMemo(() => handleCalculateTaxa(), [purchaseValue]);
    const handleCalculateMaxTickets = (total: number, sold: number) => {
        return `${total - sold}`;
    };

    const maxNumberOfTickets = useMemo(
        () => handleCalculateMaxTickets(campaign?.affiliateAmountOfTickets || 0, campaign?.amountTicketsSold || 0),
        [campaign?.amountTicketsSold, campaign?.affiliateAmountOfTickets]
    );

    function handleFormatDate(date: string) {
        // recebe AAAA-MM-DD e devolve DD/MM/AAAA
        const newDate = date.replaceAll('-', '/');
        const separateDate = newDate.split('/');
        const formatedDate = [];
        formatedDate.push(separateDate[2]);
        formatedDate.push(separateDate[1]);
        formatedDate.push(separateDate[0]);
        return `${formatedDate[0]}/${formatedDate[1]}/${formatedDate[2]}`;
    }

    function handleCalculateDifDate() {
        let date = '';
        if (campaign?.prizeDrawDate) {
            // Transforma a data do sorteio em DD/MM/AAAA

            date = campaign.prizeDrawDate.substring(0, 10);
        }
        const dateOfPrizeDraw = `${handleFormatDate(date)}`;

        const currentDate = new Date().toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
        });
        const currentFullDate = `${currentDate}`;
        // Calculando diferença de tempo entre a data do sorteio e a data atual
        const diff = moment(dateOfPrizeDraw, 'DD/MM/YYYY').diff(moment(currentFullDate, 'DD/MM/YYYY'));

        // calculando diferença em dias e horas
        const days = Math.floor(moment.duration(diff).asDays());
        if (days === 1) return 'hoje é o último dia para comprar sua rifa!';
        if (days <= 0) return 'Aguardando sorteio';
        return `${days} dias `;
    }
    if (campaign?.prizeDrawDate) handleCalculateDifDate();

    function handleSelectCoponentToRender(): number {
        let drawDone = false;
        if (campaign) {
            if (handleCalculateDifDate() === 'Aguardando sorteio') drawDone = true;
            else drawDone = false;
        }
        if (campaign && campaign.campaignStatus === 'PUBLISHED' && !drawDone) return RENDER_CAMPAIGN;
        if (campaign && campaign.campaignStatus === 'PUBLISHED' && drawDone) return ERROR_AWAIT_FINISH_CAMPAIGN;
        if (campaign && campaign.campaignStatus === 'FINISHED') return RENDER_WINNERS;
        if (campaign && campaign.campaignStatus === 'CREATED') return ERROR_AWAIT_PUBLISH_CAMPAIGN;
        if (!campaign) return ERROR_CAMPAIGN_NOT_FOUND;
        return ERROR_CAMPAIGN_NOT_FOUND;
    }

    return (
        <Container>
            {handleSelectCoponentToRender() === RENDER_CAMPAIGN && (
                <Content>
                    <ContainerHowItWorks>
                        <ContainerSteps>
                            <Step>
                                <img src={selection} alt="Compre um bilhete" />
                                <h2>Lance da sorte</h2>
                                <span>Escolha a quantidade de bilhetes e preencha os seus dados de contato</span>
                            </Step>
                            <Step>
                                <img src={creditCard} alt="Efetue o pagamento" />
                                <h2>Pagamento</h2>
                                <span>Faça o pagamento no cartão de crédito</span>
                            </Step>
                            <Step>
                                <img src={fingerCrossed} alt="Aguarde o sorteio" />
                                <h2>Dedos cruzados</h2>
                                <span>Aguarde o sorteio e torça muito!</span>
                            </Step>
                        </ContainerSteps>
                    </ContainerHowItWorks>
                    <ContainerTicket>
                        <Ticket>
                            <ContentTicket>
                                <ContainerTicketInformations>
                                    <ContainerInformations>
                                        <div className="texts">
                                            <ContainerTitleTicket>
                                                <TicketTitle>{campaign?.campaignName}</TicketTitle>
                                            </ContainerTitleTicket>
                                            <div className="infos">
                                                <span>
                                                    Valor unitário:{' '}
                                                    <NumberOfPrizeDraw>R$ {campaign?.ticketPrice}</NumberOfPrizeDraw>
                                                </span>
                                                <span>
                                                    Tempo restante:{' '}
                                                    <NumberOfPrizeDraw> {handleCalculateDifDate()}</NumberOfPrizeDraw>
                                                </span>
                                                <span>
                                                    Data do sorteio: {'  '}
                                                    <NumberOfPrizeDraw>
                                                        {formatPrizeDrawDate(campaign?.prizeDrawDate || '')}
                                                    </NumberOfPrizeDraw>
                                                </span>
                                            </div>
                                        </div>
                                    </ContainerInformations>
                                    <Prizes>
                                        <h1>
                                            Prêmios <IoTrophy />
                                        </h1>

                                        {campaign?.prize1 && (
                                            <span>
                                                <NumberOfPrizeDraw>1º</NumberOfPrizeDraw> Prêmio: {campaign.prize1}
                                            </span>
                                        )}
                                        {campaign?.prize2 && (
                                            <span>
                                                <NumberOfPrizeDraw>2º</NumberOfPrizeDraw> Prêmio: {campaign.prize2}
                                            </span>
                                        )}
                                        {campaign?.prize3 && (
                                            <span>
                                                <NumberOfPrizeDraw>3º</NumberOfPrizeDraw> Prêmio: {campaign.prize3}
                                            </span>
                                        )}
                                        {campaign?.prize4 && (
                                            <span>
                                                <NumberOfPrizeDraw>4º</NumberOfPrizeDraw> Prêmio: {campaign.prize4}
                                            </span>
                                        )}
                                        {campaign?.prize5 && (
                                            <span>
                                                <NumberOfPrizeDraw>5º</NumberOfPrizeDraw> Prêmio: {campaign.prize5}
                                            </span>
                                        )}
                                    </Prizes>
                                </ContainerTicketInformations>
                                <div className="numbers-of-sold">
                                    <span>
                                        Total vendido até agora:{' '}
                                        <NumberOfPrizeDraw>
                                            ({campaign?.amountTicketsSold}/{campaign?.affiliateAmountOfTickets})
                                        </NumberOfPrizeDraw>
                                    </span>
                                    <ProgressBar>
                                        <ProgressBarValue
                                            theme={{
                                                value: handleGetTicketSoldPercentage(
                                                    campaign?.affiliateAmountOfTickets,
                                                    campaign?.amountTicketsSold
                                                ),
                                            }}
                                        >
                                            <span>
                                                {handleGetTicketSoldPercentage(
                                                    campaign?.affiliateAmountOfTickets,
                                                    campaign?.amountTicketsSold
                                                )}
                                            </span>
                                        </ProgressBarValue>
                                    </ProgressBar>
                                </div>
                            </ContentTicket>
                            <ImageTicket>
                                <Carousel>
                                    {campaign?.prizePhotos.map(obj => {
                                        return (
                                            <Carousel.Item key={obj.id}>
                                                <PrizeDrawImage
                                                    onClick={() => {
                                                        setImageToPreview(obj.url);
                                                        handleShowOverlayImage();
                                                    }}
                                                    className="d-block w-100"
                                                    src={obj.url}
                                                    alt={obj.name}
                                                />
                                                <Carousel.Caption>
                                                    <PrizeDrawName className="prize-draw-name">
                                                        {obj.name}
                                                    </PrizeDrawName>
                                                </Carousel.Caption>
                                            </Carousel.Item>
                                        );
                                    })}
                                    {showOverlayImage && (
                                        <OverlayViewImage image={imageToPreview} showOverlay={handleShowOverlayImage} />
                                    )}
                                    {!campaign?.prizePhotos.length && (
                                        <Carousel.Item>
                                            <PrizeDrawImage
                                                className="d-block w-100"
                                                src={defaultImage}
                                                alt="Não há fotos dos prêmios dessa campanha"
                                            />
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </ImageTicket>
                        </Ticket>
                    </ContainerTicket>

                    <ContainerPayment>
                        <ContainerPurchaseSummary
                            style={{
                                pointerEvents: purchaseTicketResponseData ? 'none' : 'initial',
                            }}
                        >
                            <PurchaseTitle>Resumo da sua compra</PurchaseTitle>
                            <Subtitle>
                                Aqui você escolhe quantos bilhetes quer e preenche seus dados pessoais de contato
                            </Subtitle>
                            <span>
                                De quem está comprando a Rifa:{' '}
                                <span className="salesman">{campaign?.affiliateName}</span>
                            </span>
                            <ContainerNumberOfTickets>
                                <span>Quant. de bilhetes da sorte:</span>
                                {Number(maxNumberOfTickets) === 0 ? (
                                    <p>Bilhetes indisponíveis</p>
                                ) : (
                                    <>
                                        <div className="help">
                                            <div>
                                                <Input
                                                    name="numberOfTickets"
                                                    type="number"
                                                    value={numberOfTickets}
                                                    max={maxNumberOfTickets}
                                                    onChange={e => {
                                                        let value = Number(e.target.value);
                                                        if (value < 0) value = 0;
                                                        if (value > Number(maxNumberOfTickets))
                                                            setNumberOfTickets(Number(maxNumberOfTickets));
                                                        else setNumberOfTickets(value);
                                                    }}
                                                    onBlur={() => {
                                                        if (numberOfTickets < 1) setNumberOfTickets(1);
                                                    }}
                                                    disabled={!!purchaseTicketResponseData}
                                                />
                                            </div>
                                            <RiQuestionFill
                                                fill={colors.primary}
                                                size={30}
                                                cursor="pointer"
                                                onClick={() => handleShowNotesAboutTicket()}
                                            />
                                        </div>
                                        {showNotesAboutTickets && (
                                            <span className="note-about-tickets">
                                                Os seus números da sorte serão escolhidos aleatoriamente, e você
                                                receberá um email com a confirmação da compra e seus números.
                                            </span>
                                        )}
                                    </>
                                )}
                            </ContainerNumberOfTickets>
                            <TotalValue>
                                <span>Valor total: </span>
                                <span className="value">
                                    {purchaseValue.toLocaleString('pt-BR', {
                                        minimumFractionDigits: 2,
                                        style: 'currency',
                                        currency: 'BRL',
                                    })}
                                </span>
                                {valueWithTaxa === 'R$ 0' ? null : (
                                    <span className="fees">+ taxa de {valueWithTaxa}</span>
                                )}
                            </TotalValue>

                            <BottomBar />

                            <BuyerInformation
                                style={{
                                    pointerEvents: purchaseTicketResponseData ? 'none' : 'initial',
                                }}
                            >
                                <div className="container-input">
                                    <Input
                                        name="buyerName"
                                        label="Seu nome completo"
                                        value={buyerName}
                                        onChange={e => setBuyerName(e.target.value)}
                                        errors={errors}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                </div>
                                <div className="container-input">
                                    <Input
                                        name="buyerCpf"
                                        label="CPF"
                                        type="masked"
                                        mask="999.999.999-99"
                                        value={buyerCpf}
                                        onChange={e => setBuyerCpf(e.target.value)}
                                        errors={errors}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                </div>
                                <div className="container-input">
                                    <Input
                                        name="buyerEmail"
                                        label="Email"
                                        value={buyerEmail}
                                        onChange={e => setBuyerEmail(e.target.value)}
                                        errors={errors}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                </div>
                                <div className="container-input">
                                    <Input
                                        name="buyerTelephone"
                                        label="Celular (preferencialmente WhatsApp)"
                                        value={buyerTelephone}
                                        type="masked"
                                        mask="(99) 9 9999-9999"
                                        onChange={e => setBuyerTelephone(e.target.value)}
                                        errors={errors}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                </div>
                            </BuyerInformation>
                            <BottomBar />
                            <ContainerRegulament>
                                <h2>Lei Geral de Proteção de Dados</h2>
                                <span>
                                    Ao aceitar os termos abaixo você concorda que seus dados serão usados para sua
                                    identificação caso ganhe a rifa e também para fins promocionais do iRifei e seus
                                    parceiros.
                                </span>
                                <ContainerCheckbox>
                                    <InputPolicyTerms
                                        type="checkbox"
                                        name="policyTerms"
                                        id="policyTerms"
                                        isError={isErrorPolicyTerms}
                                        checked={policyTerms}
                                        onChange={e => setPolicyTerms(e.target.checked)}
                                    />
                                    <span>
                                        <label htmlFor="policyTerms">Concordo com os </label>
                                        <button
                                            type="button"
                                            className="color"
                                            onClick={() => handleShowTermsOfService()}
                                        >
                                            termos de serviço
                                        </button>
                                    </span>
                                </ContainerCheckbox>
                                {showTermsOfService && <OverlayTermsOfService showOverlay={handleShowTermsOfService} />}
                                <ContainerCheckbox>
                                    <InputPolicyTerms
                                        type="checkbox"
                                        name="campaignTerms"
                                        id="campaignTerms"
                                        isError={isErrorCampaignTerms}
                                        checked={campaignTerms}
                                        onChange={e => setCampaignTerms(e.target.checked)}
                                    />
                                    <span>
                                        <label htmlFor="campaignTerms">Concordo com o </label>

                                        <button
                                            type="button"
                                            className="color"
                                            onClick={() => handleShowTermsOfCampaign()}
                                        >
                                            regulamento da campanha
                                        </button>
                                    </span>
                                </ContainerCheckbox>
                                {showTermsOfCampaign && (
                                    <OverlayTermsOfCampaign
                                        showOverlay={handleShowTermsOfCampaign}
                                        regulation={campaign?.campaignRegulation || ''}
                                    />
                                )}
                            </ContainerRegulament>
                        </ContainerPurchaseSummary>
                        <ContainerPaymentMethod>
                            <PurchaseTitle>Selecione a forma de pagamento</PurchaseTitle>
                            <ChoosePaymentMethods>
                                <label htmlFor="pix">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        id="pix"
                                        value="pix"
                                        onChange={handlePaymentMethodChange}
                                        checked={paymentMethod === 'pix'}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                    <img src={pixImage} alt="PIX" />
                                </label>
                                <label htmlFor="credit">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        id="credit"
                                        value="credit"
                                        onChange={handlePaymentMethodChange}
                                        checked={paymentMethod === 'credit'}
                                        disabled={!!purchaseTicketResponseData}
                                    />
                                    <FaCreditCard size={24} /> Cartão de crédito
                                </label>
                            </ChoosePaymentMethods>

                            <PurchaseTitle>Dados para pagamento</PurchaseTitle>
                            {/* CREDIT CARD */}
                            {paymentMethod === 'credit' && (
                                <>
                                    <Subtitle>
                                        Já nessa parte você acrescenta os dados do seu cartão de crédito para efetuar a
                                        compra
                                    </Subtitle>
                                    <Cards
                                        cvc={cvv}
                                        expiry={`${validityMonth}/${validityYear}`}
                                        focused={inputFocus}
                                        name={cardName || 'SEU NOME'}
                                        number={cardNumber}
                                    />
                                    <div className="container-input" style={{ marginTop: '55px' }}>
                                        <Input
                                            name="cardCpf"
                                            label="CPF do titular do cartão"
                                            type="masked"
                                            mask="999.999.999-99"
                                            value={cardCpf}
                                            onChange={e => setCardCpf(e.target.value)}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="container-input">
                                        <Input
                                            name="cardNumber"
                                            label="Número do cartão"
                                            type="masked"
                                            mask="9999 9999 9999 9999"
                                            value={cardNumber}
                                            onChange={e => setCardNumber(e.target.value)}
                                            onFocus={() => handleInputFocus('number')}
                                            errors={errors}
                                        />
                                    </div>

                                    <div className="container-input">
                                        <Input
                                            name="cardName"
                                            label="Nome impresso no cartão"
                                            value={cardName}
                                            onChange={e => setCardName(e.target.value.toUpperCase())}
                                            errors={errors}
                                        />
                                    </div>
                                    <ContainerValityCvv>
                                        <ContainerValidity>
                                            <p>Validade</p>
                                            <div>
                                                <select
                                                    name="validityMonth"
                                                    value={validityMonth}
                                                    onChange={e => setValidityMonth(Number(e.target.value))}
                                                >
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                    <option value={5}>5</option>
                                                    <option value={6}>6</option>
                                                    <option value={7}>7</option>
                                                    <option value={8}>8</option>
                                                    <option value={9}>9</option>
                                                    <option value={10}>10</option>
                                                    <option value={11}>11</option>
                                                    <option value={12}>12</option>
                                                </select>
                                                <select
                                                    name="validityYear"
                                                    value={validityYear}
                                                    onChange={e => setValidityYear(Number(e.target.value))}
                                                >
                                                    {cardValidityYears.map((obj, index) => (
                                                        // eslint-disable-next-line react/no-array-index-key
                                                        <option key={index}>{obj}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </ContainerValidity>
                                        <ContainerCvv>
                                            <p>CVV</p>
                                            <div className="container-input">
                                                <Input
                                                    name="cvv"
                                                    type="masked"
                                                    mask="999"
                                                    value={cvv}
                                                    onChange={e => setCvv(e.target.value)}
                                                    onFocus={() => handleInputFocus('cvc')}
                                                    errors={errors}
                                                />
                                            </div>
                                        </ContainerCvv>
                                    </ContainerValityCvv>
                                    <BottomBar style={{ margin: '2rem auto' }} />
                                    <ContainerButton>
                                        <Button
                                            color="primary"
                                            onClick={() => handleBuyTicket()}
                                            icon="check"
                                            disabled={isLoading}
                                        >
                                            {!isLoading ? 'Concluir sua compra com cartão de crédito' : 'Carregando...'}
                                        </Button>
                                    </ContainerButton>
                                </>
                            )}

                            {paymentMethod === 'pix' && (
                                <>
                                    <PixPaymentMethod
                                        pixQrCode={purchaseTicketResponseData?.paymentMethod.pixQrcode}
                                        paymentCompanyProvider={
                                            purchaseTicketResponseData?.paymentMethod.paymentCompanyProvider
                                        }
                                        pixExpirationTimeMilliseconds={
                                            purchaseTicketResponseData?.paymentMethod.pixExpirationTimeMilliseconds
                                        }
                                    />
                                    {!purchaseTicketResponseData?.paymentMethod.pixQrcode && (
                                        <>
                                            <BottomBar style={{ margin: '2rem auto' }} />
                                            <ContainerButton>
                                                <Button
                                                    color="primary"
                                                    onClick={() => handleBuyTicket()}
                                                    icon="check"
                                                    disabled={isLoading}
                                                >
                                                    {!isLoading ? 'Concluir sua compra com PIX' : 'Carregando...'}
                                                </Button>
                                            </ContainerButton>
                                        </>
                                    )}
                                </>
                            )}
                        </ContainerPaymentMethod>
                    </ContainerPayment>
                </Content>
            )}
            {isLoadingScreen && (
                <Error>
                    <h1>CARREGANDO...</h1>
                </Error>
            )}
            {handleSelectCoponentToRender() === ERROR_CAMPAIGN_NOT_FOUND && !isLoadingScreen && (
                <Error>
                    <h1>Ops... Erro ao carregar a campanha 😥</h1>
                    <br />
                    <br />
                    <h1>Por favor, verifique o link e tente novamente.</h1>
                </Error>
            )}
            {handleSelectCoponentToRender() === RENDER_WINNERS && !isLoadingScreen && (
                <Winners token={handleGetAffiliateHash()} />
            )}
            {handleSelectCoponentToRender() === ERROR_AWAIT_FINISH_CAMPAIGN && !isLoadingScreen && (
                <>
                    <Error>
                        <h1>
                            Campanha ainda sem resultado divulgado pelo organizador. Volte aqui em breve para conferir o
                            (s) número (s) sorteado (s).
                        </h1>
                        <br />
                        <br />
                        <h1>E, claro: dedos cruzados para esse prêmio sair para você! 🤞💸</h1>
                    </Error>
                    <ContainerInformationsClosedCampaign>
                        <div>
                            <TicketTitle>{campaign?.campaignName}</TicketTitle>
                            <div>
                                <span>Data do sorteio: {'  '}</span>
                            </div>
                            <div>{formatPrizeDrawDate(campaign?.prizeDrawDate || '')}</div>
                        </div>
                    </ContainerInformationsClosedCampaign>
                </>
            )}
            {handleSelectCoponentToRender() === ERROR_AWAIT_PUBLISH_CAMPAIGN && !isLoadingScreen && (
                <Error>
                    <h1>
                        Ops... A campanha ainda não está no ar, por favor aguarde até o organizador finalizar a criação
                        da campanha. 😥
                    </h1>
                    <br />
                    <br />
                    <h1>Em breve o resultado aparecerá por aqui.</h1>
                </Error>
            )}
        </Container>
    );
}

export function BuyTicket(): JSX.Element {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
            <BuyTicketContent />
        </GoogleReCaptchaProvider>
    );
}
