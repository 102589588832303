import styled from 'styled-components';
import { DefaultPage } from '../../_DefaultPage';

interface IPropsButtonSetPage {
    selected: boolean;
}

export const Container = styled(DefaultPage)``;

export const Content = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.h1`
    margin-right: 2rem;
    @media (max-width: 1100px) {
        margin-bottom: 2rem;
    }
`;

export const ContainerTitleFilter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (max-width: 1100px) {
        justify-content: flex-start;
    }
`;

export const ContainerFilter = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 850px) {
        width: 100%;
    }

    div.big-fields {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }

    div.container-name {
        width: 50%;
        display: flex;
        flex-direction: column;

        @media (max-width: 700px) {
            width: 100%;
        }

        span {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    div.created-by {
        width: 40%;
        display: flex;
        flex-direction: column;

        @media (max-width: 700px) {
            width: 100%;
        }

        span {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    div.container-input {
        width: 30%;
        display: flex;
        flex-direction: column;

        @media (max-width: 700px) {
            width: 100%;
        }

        span {
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    div.container-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`;

export const ContainerTable = styled.div`
    width: 100%;
    overflow: auto;
    min-height: 10rem;
`;

export const TableOrganizers = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
    min-width: 800px;

    td {
        padding: 1rem;
        border: solid 1px var(--graphite);

        button {
            margin-left: 15px;
        }
    }

    .actions {
        display: flex;
    }

    thead tr td {
        font-size: 1.4rem;
        font-weight: bold;
        background-color: var(--gray);
        text-align: center;
    }
    tbody tr td {
        font-size: 1.2rem;
        text-align: center;
        :last-child {
            text-align: left;
        }
    }
`;

export const AutoCompleteList = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: var(--white);
    margin: 0;
    padding-left: 0;
    max-height: 15rem;

    li {
        width: 100%;
        list-style: none;
        margin-bottom: solid 1px var(--graphite);
        button {
            width: 100%;
            padding: 5px 10px;
            font-size: 1.2rem;
            color: var(--graphite);
            background-color: transparent;
            border: none;
            text-align: left;
        }
    }
`;

export const ContainerTablePages = styled.div`
    width: 100%;
    margin: 1rem 0;
    display: flex;

    button {
        padding: 0.5rem 1rem;
        background-color: transparent;
        border: none;
        font-size: 1.2rem;
        color: var(--graphite);
    }
`;

export const ButtonSetPage = styled.button<IPropsButtonSetPage>`
    background-color: transparent;
    border: none;
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    font-size: ${props => (props.selected ? '2rem' : '1.5rem')};
    margin: 0 15px;
`;
